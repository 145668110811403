import React from 'react'
import iconoWhatsApp from '../../imagenes/whatsapp.png';
import './BtnWhatsapp.css';

export const BtnWhatsapp = () => {
  return (
    <div className='btnwhatsapp'>
      <a href="https://api.whatsapp.com/send?phone=573168505674&text=%C2%A1Hola!%20%F0%9F%91%8B%F0%9F%91%8B%20Te%20damos%20la%20bienvenida%20VIRTUAL%20SPACE%20TECHNOLOGY.%20Cu%C3%A9ntanos%20%C2%BFC%C3%B3mo%20podemos%20ayudarte?%20">
        <img src={iconoWhatsApp} alt="Icono de WhatsApp"/>
      </a>

      <p className='text-btn-whatsapp'>Contacta con nosotros</p> 
    </div>
  )
}
