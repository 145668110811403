import React, { Fragment, useState } from "react";
import '../css/carp-menu-css/menu.css';
import { Anchor } from "antd";
import IconoMenu from "../imagenes/menu-btn.svg";

const { Link  } = Anchor;
const getCurrentAnchor = () => '#inicio';

export function Menu(){
  
  let [cambioMenu, setCambioMenu] = useState('');

  let cont = 0;

  const FuncionMenu = () =>{
    if(cont === 0){
      setCambioMenu('mostrar');
      cont = 1
    }else{
      cont = 0;
      setCambioMenu('');
    }
  }

  const cerrarMenu = () =>{
    setCambioMenu('');
    cont = 0;
  }

  const handleClick = (e, link) => {
    e.preventDefault();
    setCambioMenu('');
    cont = 0;
  };

  return(
    <Fragment>

      <div className="btn-menu btn-movil" onClick={FuncionMenu} > 
        <img alt='icono de menu' src={IconoMenu} />    
      </div>

      <div className={"Content-Menu " + cambioMenu} >  

      <div className="btn-cerrar" onClick={cerrarMenu}>
        <span>X</span>
      </div>

      <div className="btn-menu btn-t-e"> 
        <img alt='icono de menu' src={IconoMenu} />    
      </div>

        <Anchor affix={false} className="conten-enlaces" getCurrentAnchor={getCurrentAnchor} onClick={handleClick}>
          <Link href="#Inicio" title="Inicio"/> 
          <Link href="#Nosotros" title="Nosotros"/> 
          <Link href="#Servicios" title="Servicios"/> 
          <Link href="#Contacto" title="Contacto"/> 
        </Anchor>
      </div>
    </Fragment>
  )
}