import React from "react";
import '../css/carp-footer-css/footer.css';
import { BlobFooter } from "./_Blobs";
import  logoFooter  from "../imagenes/logo/logo.svg";
import  logosApoya  from "../imagenes/logo/logos.svg";

export function PiePagina(){

  var year = new Date().getFullYear();

  return(
    <div className="Content-footer">
      <BlobFooter />
      <div className="content-info-footer">
        <div className="content-logo-footer">
          <img src={logoFooter}  alt="logo de virtual space"/>
        </div>
        <div className="content-logos-footer">
          <img src={logosApoya} alt="f"/>
        </div>
        <p className="derechos-sitio">
        Virtual Space Technology © {year} -- Todos los derechos reservados 
        </p>
      </div>
    </div>
  )
}