window.addEventListener('load', function(){
  var Storage = parseInt(localStorage.getItem('validar')),
  modal = document.querySelector('.container_modal'),
  close = document.querySelector('.modal_icon_close');

  if(Storage === null || isNaN(Storage)){
    localStorage.setItem('validar', 1 );
  };

  function mostrarModal(){
    let Storage = parseInt(localStorage.getItem('validar'));
    if(Storage <= 3){
      localStorage.setItem('validar', Storage+1);
      modal.classList.add('mostrar')
    }
  }

  setTimeout(()=>{
    mostrarModal();
  },500) ;

  close.addEventListener('click', () =>{
    modal.classList.remove('mostrar');
  });
});

