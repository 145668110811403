import React from "react";
import { useState } from "react";
import '../css/carp-contacto-css/sessionContacto.scss';
import imgUbicacion from "../imagenes/maps+.png";
import iconFacebook from "../iconos/Facebook.svg";
import iconInstagram from "../iconos/Instagram.svg";
import FContacto from "../css/carp-contacto-css/FContacto";


export function SessionContacto(){
  const initalValue = {
    EstadoNombre: '', EstadoBtnNombre: false,
    EstadoCorreo: '', EstadoBtnCorreo: false,
    EstadoNumero: '', EstadoBtnNumero: false,
    EstadoAsunto: '', EstadoBtnAsunto: false,
  }
  const [clase, setClase] = useState(initalValue);

  const ValidarCampo = (event) =>{
    let value = event.target.value;
    let name = event.target.name;
    let cantidad = Object.entries(event.target.value).length;

    if(name === 'user_name' && !(value.trim())){
      setClase({...clase, "EstadoNombre":" Error","EstadoBtnNombre": false});
    }else if(name === 'user_name' && value !== " "){
      setClase({...clase, "EstadoNombre":" Verificado","EstadoBtnNombre": true});
    }

    if(name === 'user_email' && (value.search('@')) === -1){
      setClase({...clase, "EstadoCorreo":" Error","EstadoBtnCorreo": false});
    }else if(name === 'user_email' && (value.search('@'))){
      setClase({...clase, "EstadoCorreo":" Verificado","EstadoBtnCorreo": true});
    }

    
    if(name === 'user_number' && cantidad < 9){
      setClase({...clase, "EstadoNumero":" Error", "EstadoBtnNumero": false});
    }else if(name === 'user_number' && cantidad === 10){
      setClase({...clase, "EstadoNumero":" Verificado","EstadoBtnNumero":true});
    }

    if(name === 'user_asunto' && !(value.trim())){
      setClase({...clase,"EstadoAsunto":" Error","EstadoBtnAsunto":false})
    }else if(name === 'user_asunto' && value !== " "){
      setClase({...clase,"EstadoAsunto":" Verificado","EstadoBtnAsunto":true})
    }
  }

  /* Funcion confimar campos y enviar correo */
    const sendEmail = (event) =>{
      event.preventDefault();

      if(clase.EstadoBtnNombre === true && clase.EstadoBtnCorreo === true && clase.EstadoBtnNumero === true && clase.EstadoBtnAsunto === true){
        console.log('hola');
        EnviarFormulario(event);
        setClase({...clase,
          "EstadoNombre":" ",
          "EstadoCorreo":" ",
          "EstadoNumero":" ",
          "EstadoAsunto":" ",
        });
      }else{
        VentanaEmergente(
          '¡Upss!',
          'Alguno de los campos se encuentra vacio o mal dilegenciado, por favor verifeque los campos e intentelo de nuevo', 
          `<script src="https://cdn.lordicon.com/ritcuqlt.js"></script>
          <lord-icon
              src="https://cdn.lordicon.com/bmnlikjh.json"
              trigger="hover"
              colors="primary:#ff0017"
              state="hover-2"
              style="width:100px;height:100px">
          </lord-icon>`,
          3500
          );
      }
    }


    

  const {EnviarFormulario,VentanaEmergente} = FContacto();
  const abrirRed = (url) =>{
    window.open(url, '_blank');
  }

  return(
    <div className="Content-SessionContacto section" id="Contacto">
      <div className="content-title">
        <h2 className="titlo-contacto">Contacto</h2>
      </div>

      <div className="content-form">

        <div className="content-formularios">

          <form className="form form-persona form-active" onSubmit={sendEmail}>
            <h3 className="titulo-form">Contáctanos</h3>
            <p className="form-text">Creamos tu negocio en espacios Online</p>
            <input className={'input' + clase.EstadoNombre} type="text" name="user_name" data-estado="EstadoNombre" placeholder="Nombre completo" onInput={ValidarCampo} />
            <input className={'input' + clase.EstadoCorreo} type="email" name="user_email" data-estado="EstadoCorreo" placeholder="Correo electronico" onInput={ValidarCampo} />
            <input className={'input' + clase.EstadoNumero} type="number" name="user_number" data-estado="EstadoNumero" placeholder="Número ejemplo +57 3215228585"onInput={ValidarCampo} />
            <textarea className={"input area" + clase.EstadoAsunto} name="user_asunto" data-estado="EstadoAsunto"  id="" cols="30" rows="10" placeholder="Asunto" onInput={ValidarCampo}></textarea>
            <input type="submit" className="btn-form" value='Enviar formulario'/>
          </form> 

        </div>
        <div className="content-info-form">
          <div className="content-text-info-form">
            <h3>Contacto</h3>
            <a className="enlace-form" href="mailto:comercial@virtualspacetics.com.co">Correo: comercial@virtualspacetics</a>
            <a className="enlace-form" href="tel:+573168505674">Celular: 3168505674</a> 
            <a className="enlace-form" href="https://goo.gl/maps/pmVC8N4u8sjtzVDY7">
              <img className="img-maps" alt="ubicacion" src={imgUbicacion}/>
            </a>
            <div className="content-redes-sociales">
              <a onClick={() => abrirRed('https://www.facebook.com/virtualspace.agencia/')} href=" " className="enlaces facebook">
                <img className="iconos" src={iconFacebook} alt="icono de facebook"/>
              </a>
              <a onClick={() => abrirRed('https://instagram.com/virtualspace.agencia?igshid=YmMyMTA2M2Y=')}  href=" " className="enlaces instgram">
                <img className="iconos" src={iconInstagram} alt="icono de instagram"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};