import React from 'react';
import './VentanaModal.scss';
import icono_btn_modal from "./../../iconos/icono-boton-contactanos-modal.svg";
import icono_close from "../../iconos/close.svg";
import circulo_superior from "../../iconos/circulos_decorativo_superior_izquierdo.svg";
import circulo_inferior from "../../iconos/circulo_decorativo_inferior_derecho.svg";
import './local';

export const VentanaModal = () => {
  return (
    <section className='container_modal'>
      <div className='modal'>
        <img className='modal_icon_close' src={icono_close} alt='icono close modal'/>
        <img className='circulo_superior' src={circulo_superior} alt='circulo decorativo superior izquierdo'/>
        <img className='circulo_inferior' src={circulo_inferior} alt='circulo decorativo inferior derecho' />

        <h2 className='modal_titulo'>Desarrollo Web</h2>
        <p className='modal_texto'>Desarrollo de sitios web innovadores y funcionales, desarrollo web a la medida</p>

        <a className='btn-modal' href='https://h3r0.link/V9PfQf2E897qgrBs9' target="_blank" rel="noreferrer">
          <img className='icono-btn-modal' src={icono_btn_modal} alt='icono de cohete' />
          <p className='text-btn-modal'>Contáctanos</p>
        </a>
      </div>
    </section>
  )
}
