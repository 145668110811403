import Swal from 'sweetalert2';
import lottie from 'lottie-web';
import { defineElement } from "lord-icon-element";
import emailjs from "@emailjs/browser";
import './sessionContacto.scss';


const FContacto = () =>{
  //iconos lottie
  
  defineElement(lottie.loadAnimation);
  const  VentanaEmergente = (titulo,contenido,icono,tiempo) => {
    Swal.fire({
      position:'bottom-start',
      html:`
      <div class="Content-alert-from">
        <div class="content-icono-alert">
          ${icono}
        </div>
        <div class="content-text-alert">
          <h2 class="title-alert">${titulo}</h2>
          <p class="text-alert">${contenido}</p>
        </div>
      </div> 
      `,
      timer: tiempo,
      timerProgressBar: true,
      showConfirmButton: false,
      customClass: {
        container: 'container-pop'
      }
    })
  }

  const EnviarFormulario = (evento) => {
    emailjs.sendForm('service_o0y5spn', 'template_4ehotfj', evento.target, 'oL5RsqXatx-pMLkxU')
    .then(response => {
      console.log(response)
      VentanaEmergente(
        '¡Felicidades!',
        'Tu correo ha sido enviado exitosamente, pronto nos pondremos en contanto',
        `
        <script src="https://cdn.lordicon.com/ritcuqlt.js"></script>
        <lord-icon
            src="https://cdn.lordicon.com/egiwmiit.json"
            trigger="hover"
            colors="primary:#30e849"
            state="hover"
            style="width:100px;height:100px">
        </lord-icon>
        `,
        5000
      );
      evento.target.reset();
    })
    .catch(error =>{
      VentanaEmergente(
        '¡Upss!',
        'Algo salio mal con el servidor, estamos trabajando para solucionarlo. En unos minutos estaremos nuevamente en linea', 
        `<script src="https://cdn.lordicon.com/ritcuqlt.js"></script>
        <lord-icon
            src="https://cdn.lordicon.com/bmnlikjh.json"
            trigger="hover"
            colors="primary:#ff0017"
            state="hover-2"
            style="width:100px;height:100px">
        </lord-icon>`,
        5000
        );
    })
  }

  return {EnviarFormulario,VentanaEmergente}
}

export default FContacto;