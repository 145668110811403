import React, { useEffect } from "react";
import '../css/carp-nosotros-css/sessionNosotros.css';
import { Card } from "./_Card";
import { useState } from "react";

export function SessionNosotros(){
  var clases = {card1:'mostrar',card2:'',card3:'',barra1:'activa',barra2:'',barra3:''}
  var [cambioClaseCard, setCambioClaseCard] = useState({});
  var contador = 1;

  const carousel1 = () =>{
    if(contador === 1){
      setCambioClaseCard(clases);
      contador++;
      setTimeout(()=>{
        carousel2();
      },8000);
    }
  }
  const carousel2 = () =>{
    if(contador === 2){
      setCambioClaseCard({card1:'',card2:'mostrar',card3:'',barra1:'',barra2:'activa',barra3:''});
      contador++;
      setTimeout(()=>{
        carousel3();
      },8000); 
    } 
  }

  const carousel3 = () =>{
    setCambioClaseCard({card1:'',card2:'',card3:'mostrar',barra1:'',barra2:'',barra3:'activa'});
    contador=1;
    setTimeout(()=>{
      carousel1();
    },8000);
  }

  useEffect(() =>{
    carousel1();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return(
    <div className="Content-SessionNostostros section" id="Nosotros">
      <div className="content-cards"> 
          <Card
            clase={'card1 ' + cambioClaseCard.card1}
            titulo='¿Quienes'
            subtitulo='Somos?'
            textoCard='Somos una desarrolladora de contenidos digitales en soluciones TIC ubicada en la ciudad 
            de Medellín. Brindamos las herramientas para gestionar de forma eficiente las relaciones 
            con los clientes y la colaboración entre los equipos de trabajo, ayudando a que las áreas 
            comerciales de los emprendedores, pymes y comercio en general  sean más competentes y
            efectivas para comercializar sus productos en internet y canales digitales.'
          />

        <Card 
          clase={'card2 ' + cambioClaseCard.card2}
          titulo='Misión'
          textoCard='Ayudar a los emprendedores, mipymes y comercio en general en la transformación digital 
          de sus empresas, desarrollando contenidos digitales que agreguen valor y competitividad en
          el mercado que se encuentren.'
        />

        <Card 
          clase={'card3 ' + cambioClaseCard.card3}
          titulo='Visión'
          textoCard='Al 2025 VIRTUAL SPACE TECHNOLOGY estará posicionada en el Área Metropolitana del 
          Valle de Aburrá como el aliado estratégico para el crecimiento digital a través de contenidos 
          digitales.'
        />
      </div>
      <div className="content-controles">
        <span className={'barra ' + cambioClaseCard.barra1} ></span>
        <span className={'barra ' + cambioClaseCard.barra2} ></span>
        <span className={'barra ' + cambioClaseCard.barra3} ></span>
      </div>

    </div>
  )
}