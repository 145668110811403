import React from "react";
import '../css/carp-home-css/sessionhome.css';
import logo from '../imagenes/logo/logo.svg';
import { BlobHeader } from "./_Blobs";

export function SessionHome(){
  return(
    <div className="Content-SessionHome section" id="Inicio">
      <section className="section-elementos">
        <div className="content-logo">
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div className="content-header">
          <div className="content-text-header">
            <h1 className="title">
              Elevando
              <span className="title-2">
                Negocios
              </span>
            </h1>
            <h2 className="texto-header">
              Conquistar espacios es crear resultados.
            </h2>
            <a className="btn-header" href="#Contacto"> 
              Comienza con nosotros
            </a>
          </div>
        </div>
      </section>
      <BlobHeader clase="blob-header" />
    </div>
  )
} 