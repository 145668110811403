import React from 'react'
import astronauta from '../imagenes/astronauta.png';

export const BlobHeader = (props) =>{
  return(
    <div className='content-blob-header'>
      <svg className={props.clase} width="829" height="985" viewBox="0 0 829 985" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_210_20" style={{maskType:`alpha`}} maskUnits="userSpaceOnUse" x="0" y="0" width="829" height="985">
        <rect width="829" height="985" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_210_20)">
        <path fill="url(#paint0_linear_210_20)">
          <animate 
            attributeName='d'
            dur='15s'
            repeatCount='indefinite'

            values='M990.091 564.35C1028.07 637.962 1020.8 701.058 968.272 753.639C915.742 806.219 861.193 865.675 804.623 932.007C748.053 998.339 682.998 999.957 609.457 936.861C535.917 873.764 464.396 840.194 394.896 836.149C325.396 832.105 284.181 794.49 271.251 723.304C258.321 652.118 215.489 589.426 142.757 535.228C70.0241 481.03 67.1956 425.619 134.271 368.994C201.347 312.369 251.047 258.575 283.373 207.613C315.699 156.65 356.914 106.497 407.018 57.1523C457.123 7.80768 516.117 -9.58425 584.001 4.97644C651.885 19.5371 708.859 48.6585 754.923 92.3406C800.987 136.023 856.748 172.424 922.208 201.546C987.667 230.667 1005.85 280.012 976.757 349.579C947.664 419.147 952.109 490.737 990.091 564.35Z;
            
            M1181.23 641.849C1208.17 736.946 1165.06 790.325 1051.89 801.988C938.712 813.651 857.873 847.293 809.37 902.916C760.867 958.538 701.585 977.378 631.525 959.436C561.465 941.493 501.285 912.336 450.986 871.965C400.686 831.594 334.668 799.297 252.932 775.074C171.195 750.851 128.53 698.817 124.937 618.972C121.344 539.127 128.081 462.87 145.147 390.202C162.213 317.534 189.608 246.212 227.333 176.235C265.057 106.258 318.95 46.5988 389.01 -2.74372C459.07 -52.0862 535.417 -65.0947 618.052 -41.7692C700.687 -18.4436 773.891 13.4047 837.663 53.7759C901.436 94.1471 952.634 144.387 991.257 204.495C1029.88 264.603 1064.46 328.748 1095 396.931C1125.54 465.113 1154.28 546.753 1181.23 641.849Z;
            
            M1063.64 593.381C1077.7 673.797 1047.64 732.352 973.467 769.047C899.297 805.741 827.08 815.891 756.813 799.495C686.547 783.1 626.04 785.052 575.292 805.351C524.545 825.65 462.086 838.923 387.916 845.168C313.746 851.414 257.143 824.869 218.106 765.533C179.07 706.198 168.53 642.568 186.486 574.644C204.443 506.72 204.834 439.186 187.658 372.043C170.481 304.9 183.364 244.003 226.304 189.351C269.244 134.7 318.04 74.9734 372.692 10.1724C427.343 -54.6286 495.658 -81.5639 577.635 -70.6336C659.612 -59.7034 739.247 -39.7946 816.54 -10.9074C893.832 17.9798 921.939 82.7808 900.859 183.496C879.779 284.21 898.126 357.599 955.901 403.663C1013.68 449.726 1049.59 512.966 1063.64 593.381Z;
            
            M911.72 526.561C866.009 572.272 850.126 640.064 864.072 729.937C878.018 819.81 850.513 884.116 781.559 922.854C712.605 961.593 645.588 949.971 580.507 887.99C515.427 826.008 449.572 796.567 382.942 799.666C316.312 802.765 255.493 781.847 200.484 736.91C145.476 691.974 103.639 634.254 74.9723 563.75C46.3059 493.246 73.8101 435.914 157.485 391.752C241.16 347.59 288.033 296.068 298.105 237.186C308.177 178.304 334.519 114.386 377.131 45.4313C419.743 -23.5229 480.562 -58 559.589 -58C638.615 -58 717.254 -45.9911 795.505 -21.9734C873.756 2.04439 909.008 61.7014 901.261 156.998C893.513 252.294 904.747 326.284 934.963 378.968C965.179 431.652 957.431 480.85 911.72 526.561Z;
            
            M1200.32 409.058C1164.32 479.072 1162.52 558.281 1194.9 646.685C1227.29 735.089 1204.61 794.782 1126.87 825.766C1049.12 856.75 976.846 887.492 910.035 917.994C843.223 948.496 776.298 948.792 709.259 918.882C642.22 888.973 560.422 875.894 463.867 879.645C367.311 883.396 280.271 850.829 202.746 781.943C125.222 713.058 121.139 631.201 190.499 536.374C259.858 441.546 288.526 348.681 276.503 257.778C264.48 166.876 302.168 108.416 389.567 82.4001C476.966 56.384 551.983 -1.95146 614.618 -92.6062C677.253 -183.261 750.73 -199.267 835.047 -140.625C919.365 -81.9827 1015.97 -49.4578 1124.85 -43.0504C1233.73 -36.6429 1282.53 22.8069 1271.25 135.299C1259.96 247.791 1236.32 339.044 1200.32 409.058Z;
            
            M990.091 564.35C1028.07 637.962 1020.8 701.058 968.272 753.639C915.742 806.219 861.193 865.675 804.623 932.007C748.053 998.339 682.998 999.957 609.457 936.861C535.917 873.764 464.396 840.194 394.896 836.149C325.396 832.105 284.181 794.49 271.251 723.304C258.321 652.118 215.489 589.426 142.757 535.228C70.0241 481.03 67.1956 425.619 134.271 368.994C201.347 312.369 251.047 258.575 283.373 207.613C315.699 156.65 356.914 106.497 407.018 57.1523C457.123 7.80768 516.117 -9.58425 584.001 4.97644C651.885 19.5371 708.859 48.6585 754.923 92.3406C800.987 136.023 856.748 172.424 922.208 201.546C987.667 230.667 1005.85 280.012 976.757 349.579C947.664 419.147 952.109 490.737 990.091 564.35Z'
          ></animate>  
        </path>
      </g>
      <defs>
        <linearGradient id="paint0_linear_210_20" x1="86" y1="0" x2="86" y2="983" gradientUnits="userSpaceOnUse">
          <stop stopColor="#376EFF"/>
          <stop offset="1" stopColor="#0E0A3D"/>
        </linearGradient>
      </defs>
      </svg>
      <img className='img-astronauta' alt="animacion de borbuja" src={astronauta} />
    </div> 
  )
}

export const BlobServicio = (props) =>{
  return(
    <div className='content-blob-servicio'>
      <svg className={props.clase} width="829" height="985" viewBox="0 0 829 985" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_210_20" style={{maskType:`alpha`}} maskUnits="userSpaceOnUse" x="0" y="0" width="829" height="985">
        <rect width="829" height="985" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_210_20)">
        <path fill="url(#paint0_linear_210_20)">
          <animate 
            attributeName='d'
            dur='15s'
            repeatCount='indefinite'

            values='M990.091 564.35C1028.07 637.962 1020.8 701.058 968.272 753.639C915.742 806.219 861.193 865.675 804.623 932.007C748.053 998.339 682.998 999.957 609.457 936.861C535.917 873.764 464.396 840.194 394.896 836.149C325.396 832.105 284.181 794.49 271.251 723.304C258.321 652.118 215.489 589.426 142.757 535.228C70.0241 481.03 67.1956 425.619 134.271 368.994C201.347 312.369 251.047 258.575 283.373 207.613C315.699 156.65 356.914 106.497 407.018 57.1523C457.123 7.80768 516.117 -9.58425 584.001 4.97644C651.885 19.5371 708.859 48.6585 754.923 92.3406C800.987 136.023 856.748 172.424 922.208 201.546C987.667 230.667 1005.85 280.012 976.757 349.579C947.664 419.147 952.109 490.737 990.091 564.35Z;
            
            M1181.23 641.849C1208.17 736.946 1165.06 790.325 1051.89 801.988C938.712 813.651 857.873 847.293 809.37 902.916C760.867 958.538 701.585 977.378 631.525 959.436C561.465 941.493 501.285 912.336 450.986 871.965C400.686 831.594 334.668 799.297 252.932 775.074C171.195 750.851 128.53 698.817 124.937 618.972C121.344 539.127 128.081 462.87 145.147 390.202C162.213 317.534 189.608 246.212 227.333 176.235C265.057 106.258 318.95 46.5988 389.01 -2.74372C459.07 -52.0862 535.417 -65.0947 618.052 -41.7692C700.687 -18.4436 773.891 13.4047 837.663 53.7759C901.436 94.1471 952.634 144.387 991.257 204.495C1029.88 264.603 1064.46 328.748 1095 396.931C1125.54 465.113 1154.28 546.753 1181.23 641.849Z;
            
            M1063.64 593.381C1077.7 673.797 1047.64 732.352 973.467 769.047C899.297 805.741 827.08 815.891 756.813 799.495C686.547 783.1 626.04 785.052 575.292 805.351C524.545 825.65 462.086 838.923 387.916 845.168C313.746 851.414 257.143 824.869 218.106 765.533C179.07 706.198 168.53 642.568 186.486 574.644C204.443 506.72 204.834 439.186 187.658 372.043C170.481 304.9 183.364 244.003 226.304 189.351C269.244 134.7 318.04 74.9734 372.692 10.1724C427.343 -54.6286 495.658 -81.5639 577.635 -70.6336C659.612 -59.7034 739.247 -39.7946 816.54 -10.9074C893.832 17.9798 921.939 82.7808 900.859 183.496C879.779 284.21 898.126 357.599 955.901 403.663C1013.68 449.726 1049.59 512.966 1063.64 593.381Z;
            
            M911.72 526.561C866.009 572.272 850.126 640.064 864.072 729.937C878.018 819.81 850.513 884.116 781.559 922.854C712.605 961.593 645.588 949.971 580.507 887.99C515.427 826.008 449.572 796.567 382.942 799.666C316.312 802.765 255.493 781.847 200.484 736.91C145.476 691.974 103.639 634.254 74.9723 563.75C46.3059 493.246 73.8101 435.914 157.485 391.752C241.16 347.59 288.033 296.068 298.105 237.186C308.177 178.304 334.519 114.386 377.131 45.4313C419.743 -23.5229 480.562 -58 559.589 -58C638.615 -58 717.254 -45.9911 795.505 -21.9734C873.756 2.04439 909.008 61.7014 901.261 156.998C893.513 252.294 904.747 326.284 934.963 378.968C965.179 431.652 957.431 480.85 911.72 526.561Z;
            
            M1200.32 409.058C1164.32 479.072 1162.52 558.281 1194.9 646.685C1227.29 735.089 1204.61 794.782 1126.87 825.766C1049.12 856.75 976.846 887.492 910.035 917.994C843.223 948.496 776.298 948.792 709.259 918.882C642.22 888.973 560.422 875.894 463.867 879.645C367.311 883.396 280.271 850.829 202.746 781.943C125.222 713.058 121.139 631.201 190.499 536.374C259.858 441.546 288.526 348.681 276.503 257.778C264.48 166.876 302.168 108.416 389.567 82.4001C476.966 56.384 551.983 -1.95146 614.618 -92.6062C677.253 -183.261 750.73 -199.267 835.047 -140.625C919.365 -81.9827 1015.97 -49.4578 1124.85 -43.0504C1233.73 -36.6429 1282.53 22.8069 1271.25 135.299C1259.96 247.791 1236.32 339.044 1200.32 409.058Z;
            
            M990.091 564.35C1028.07 637.962 1020.8 701.058 968.272 753.639C915.742 806.219 861.193 865.675 804.623 932.007C748.053 998.339 682.998 999.957 609.457 936.861C535.917 873.764 464.396 840.194 394.896 836.149C325.396 832.105 284.181 794.49 271.251 723.304C258.321 652.118 215.489 589.426 142.757 535.228C70.0241 481.03 67.1956 425.619 134.271 368.994C201.347 312.369 251.047 258.575 283.373 207.613C315.699 156.65 356.914 106.497 407.018 57.1523C457.123 7.80768 516.117 -9.58425 584.001 4.97644C651.885 19.5371 708.859 48.6585 754.923 92.3406C800.987 136.023 856.748 172.424 922.208 201.546C987.667 230.667 1005.85 280.012 976.757 349.579C947.664 419.147 952.109 490.737 990.091 564.35Z'
          ></animate>  
        </path>
      </g>
      <defs>
        <linearGradient id="paint0_linear_210_20" x1="86" y1="0" x2="86" y2="983" gradientUnits="userSpaceOnUse">
          <stop stopColor="#376EFF"/>
          <stop offset="1" stopColor="#0E0A3D"/>
        </linearGradient>
      </defs>
      </svg>
    </div> 
  )
}

export const BlobFooter = (props) =>{
  return(
    <div className='content-blob-footer'>
      <svg className={props.clase} width="829" height="985" viewBox="0 0 829 985" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_210_20" style={{maskType:`alpha`}} maskUnits="userSpaceOnUse" x="0" y="0" width="829" height="985">
        <rect width="829" height="985" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_210_20)">
        <path fill="url(#paint0_linear_210_20)">
          <animate 
            attributeName='d'
            dur='15s'
            repeatCount='indefinite'

            values='M990.091 564.35C1028.07 637.962 1020.8 701.058 968.272 753.639C915.742 806.219 861.193 865.675 804.623 932.007C748.053 998.339 682.998 999.957 609.457 936.861C535.917 873.764 464.396 840.194 394.896 836.149C325.396 832.105 284.181 794.49 271.251 723.304C258.321 652.118 215.489 589.426 142.757 535.228C70.0241 481.03 67.1956 425.619 134.271 368.994C201.347 312.369 251.047 258.575 283.373 207.613C315.699 156.65 356.914 106.497 407.018 57.1523C457.123 7.80768 516.117 -9.58425 584.001 4.97644C651.885 19.5371 708.859 48.6585 754.923 92.3406C800.987 136.023 856.748 172.424 922.208 201.546C987.667 230.667 1005.85 280.012 976.757 349.579C947.664 419.147 952.109 490.737 990.091 564.35Z;
            
            M1181.23 641.849C1208.17 736.946 1165.06 790.325 1051.89 801.988C938.712 813.651 857.873 847.293 809.37 902.916C760.867 958.538 701.585 977.378 631.525 959.436C561.465 941.493 501.285 912.336 450.986 871.965C400.686 831.594 334.668 799.297 252.932 775.074C171.195 750.851 128.53 698.817 124.937 618.972C121.344 539.127 128.081 462.87 145.147 390.202C162.213 317.534 189.608 246.212 227.333 176.235C265.057 106.258 318.95 46.5988 389.01 -2.74372C459.07 -52.0862 535.417 -65.0947 618.052 -41.7692C700.687 -18.4436 773.891 13.4047 837.663 53.7759C901.436 94.1471 952.634 144.387 991.257 204.495C1029.88 264.603 1064.46 328.748 1095 396.931C1125.54 465.113 1154.28 546.753 1181.23 641.849Z;
            
            M1063.64 593.381C1077.7 673.797 1047.64 732.352 973.467 769.047C899.297 805.741 827.08 815.891 756.813 799.495C686.547 783.1 626.04 785.052 575.292 805.351C524.545 825.65 462.086 838.923 387.916 845.168C313.746 851.414 257.143 824.869 218.106 765.533C179.07 706.198 168.53 642.568 186.486 574.644C204.443 506.72 204.834 439.186 187.658 372.043C170.481 304.9 183.364 244.003 226.304 189.351C269.244 134.7 318.04 74.9734 372.692 10.1724C427.343 -54.6286 495.658 -81.5639 577.635 -70.6336C659.612 -59.7034 739.247 -39.7946 816.54 -10.9074C893.832 17.9798 921.939 82.7808 900.859 183.496C879.779 284.21 898.126 357.599 955.901 403.663C1013.68 449.726 1049.59 512.966 1063.64 593.381Z;
            
            M911.72 526.561C866.009 572.272 850.126 640.064 864.072 729.937C878.018 819.81 850.513 884.116 781.559 922.854C712.605 961.593 645.588 949.971 580.507 887.99C515.427 826.008 449.572 796.567 382.942 799.666C316.312 802.765 255.493 781.847 200.484 736.91C145.476 691.974 103.639 634.254 74.9723 563.75C46.3059 493.246 73.8101 435.914 157.485 391.752C241.16 347.59 288.033 296.068 298.105 237.186C308.177 178.304 334.519 114.386 377.131 45.4313C419.743 -23.5229 480.562 -58 559.589 -58C638.615 -58 717.254 -45.9911 795.505 -21.9734C873.756 2.04439 909.008 61.7014 901.261 156.998C893.513 252.294 904.747 326.284 934.963 378.968C965.179 431.652 957.431 480.85 911.72 526.561Z;
            
            M1200.32 409.058C1164.32 479.072 1162.52 558.281 1194.9 646.685C1227.29 735.089 1204.61 794.782 1126.87 825.766C1049.12 856.75 976.846 887.492 910.035 917.994C843.223 948.496 776.298 948.792 709.259 918.882C642.22 888.973 560.422 875.894 463.867 879.645C367.311 883.396 280.271 850.829 202.746 781.943C125.222 713.058 121.139 631.201 190.499 536.374C259.858 441.546 288.526 348.681 276.503 257.778C264.48 166.876 302.168 108.416 389.567 82.4001C476.966 56.384 551.983 -1.95146 614.618 -92.6062C677.253 -183.261 750.73 -199.267 835.047 -140.625C919.365 -81.9827 1015.97 -49.4578 1124.85 -43.0504C1233.73 -36.6429 1282.53 22.8069 1271.25 135.299C1259.96 247.791 1236.32 339.044 1200.32 409.058Z;
            
            M990.091 564.35C1028.07 637.962 1020.8 701.058 968.272 753.639C915.742 806.219 861.193 865.675 804.623 932.007C748.053 998.339 682.998 999.957 609.457 936.861C535.917 873.764 464.396 840.194 394.896 836.149C325.396 832.105 284.181 794.49 271.251 723.304C258.321 652.118 215.489 589.426 142.757 535.228C70.0241 481.03 67.1956 425.619 134.271 368.994C201.347 312.369 251.047 258.575 283.373 207.613C315.699 156.65 356.914 106.497 407.018 57.1523C457.123 7.80768 516.117 -9.58425 584.001 4.97644C651.885 19.5371 708.859 48.6585 754.923 92.3406C800.987 136.023 856.748 172.424 922.208 201.546C987.667 230.667 1005.85 280.012 976.757 349.579C947.664 419.147 952.109 490.737 990.091 564.35Z'
          ></animate>  
        </path>
      </g>
      <defs>
        <linearGradient id="paint0_linear_210_20" x1="86" y1="0" x2="86" y2="983" gradientUnits="userSpaceOnUse">
          <stop stopColor="#376EFF"/>
          <stop offset="1" stopColor="#0E0A3D"/>
        </linearGradient>
      </defs>
      </svg>
    </div> 
  )
}
