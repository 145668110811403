import React from 'react';
import './App.scss';
import { Menu } from './componentes/menu';
import { VentanaModal } from './componentes/modal/VentanaModal';
import { SessionHome } from './componentes/sessionhome';
import { Video } from './componentes/video-empresa/video';
import { SessionNosotros } from './componentes/session-nosotros';
import { SessionServicios } from './componentes/session-servicios';
import { SessionContacto } from './componentes/session-contacto';
import { PiePagina } from './componentes/footer';
import { BtnWhatsapp } from './componentes/whatsapp-btn/BtnWhatsapp';

function App() {
  return (
    <div>
      <Menu />
      <VentanaModal/>
      <SessionHome />
      <Video />
      <SessionNosotros />
      <SessionServicios />
      <SessionContacto />
      <PiePagina />

      {/* Boton de WhatsApp */}
     <BtnWhatsapp /> 
    </div>
  );
}

export default App;
