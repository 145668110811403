import React from 'react'
import iconMas from '../iconos/mas.svg';

export const CardServicio = (props) => {
  return (
    <div className='card-servicio' onClick={props.clic}>
      <h3>{props.titulo}</h3>
      <div className='btn-servicios' id={props.di}>
          <img 
            src={iconMas}
            alt='boton de más'  
            className='btn-card-servicio'
            ref={props.refe}
          />
      </div>
    </div>
  )
}
