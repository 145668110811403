import React from 'react'

export const Card = (propsCard) => {

  return (
    <div ref={propsCard.refe}  className={'content-card ' + propsCard.clase}>
      <h2 className='titulo-card'>{propsCard.titulo} <br/> <span>{propsCard.subtitulo}</span></h2>
      <p className='texto-card'>{propsCard.textoCard}</p>
    </div>
  )
}
