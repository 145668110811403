import React from 'react'
import './video.css';
import mivideo from './../../video/video.mp4';

export const Video = () => {
  return (
    <div className='contenedor-video'>
      <video className='video' controls loop muted autoPlay>
        <source src={mivideo}/>
      </video>
    </div>
  )
}
