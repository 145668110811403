import React from "react";
import '../css/carp-servicios-css/sessionServicios.css';
import { BlobServicio } from "./_Blobs";
import { CardServicio } from "./_CardServicio";
import { useRef } from "react";

export function SessionServicios(){

  var textCard1 = useRef(),
      textCard2 = useRef(),
      textCard3 = useRef(),
      textCard4 = useRef();

    var btnCard1 = useRef(null),
        btnCard2 = useRef(null),
        btnCard3 = useRef(null);

  const cambiarInfo = (e, accion) =>{
    if(accion === 'desarrolloWeb'){
      textCard1.current.classList.remove('visible');
      textCard3.current.classList.remove('visible');
      textCard4.current.classList.remove('visible');

      textCard2.current.classList.add('visible');
    }else if(accion === 'diseñoGrafico'){
      textCard1.current.classList.remove('visible');
      textCard2.current.classList.remove('visible');
      textCard4.current.classList.remove('visible');

      textCard3.current.classList.add('visible');
    }else if(accion === 'socialMedia'){
      textCard1.current.classList.remove('visible');
      textCard2.current.classList.remove('visible');
      textCard3.current.classList.remove('visible');

      textCard4.current.classList.add('visible');
    }
  }

  return(
    <div className="Content-SessionServicios section" id="Servicios">
      <BlobServicio/>
      <div className="content-text-servicios">
        <div className="text-servicios visible" ref={textCard1}>
          <h2 className="titulo-servicios">Servicios</h2>
          <p className="texto-servicios">
          Siendo esencial conectar las empresas a nuevas condiciones para comunicarse y estar en contacto, obteniendo un mayor alcance para que las personas conozcan el negocio y de esta manera poder vender es necesario contar con contenidos digitales. Por lo tanto, en VIRTUAL SPACE buscamos ayudarle con creación de contenidos digitales en soluciones TIC.
          </p>
        </div>
        <div className="text-servicios" ref={textCard2}>
          <h2 className="titulo-servicios">Desarrollo Web</h2>
          <ul className="texto-servicios">
            <li>Diseño web</li>
            <li>Desarrollo web</li>
            <li>Desarrollo a la medida (software)</li>
            <li>Ecommerce</li>
            <li>Desarrollo de aplicaciones móviles</li> 
          </ul>
        </div>
        <div className="text-servicios" ref={textCard3}>
          <h2 className="titulo-servicios">Diseño Grafico</h2>
          <ul className="texto-servicios">
            <li>Diseño de marca</li>
            <li>Branding</li>
            <li>Volantes </li>
            <li>Folletos</li>
            <li>Plegables </li>
            <li>Catálogos</li>
            <li>Diseño de papelería corporativa</li>
            <li>Presentaciones </li>
            <li>Diseño de tarjetas de presentación</li>
            <li>Producción Audiovisual corporativa</li>
            <li>Fotografía de producto</li>    
          </ul>
        </div>
        <div className="text-servicios" ref={textCard4}>
          <h2 className="titulo-servicios">Social Media</h2>
          <ul className="texto-servicios">
            <li> Social Media</li>
            <li> Pauta digital en redes sociales</li>
          </ul>
        </div>
      </div> 
      <div className="content-cards-servicios">
        <CardServicio titulo="Desarrollo Web" di="1" refe={btnCard1} clic={e => cambiarInfo(e, 'desarrolloWeb')} />
        <CardServicio titulo="Diseño Grafico" di="2" refe={btnCard2} clic={e => cambiarInfo(e, 'diseñoGrafico')} />
        <CardServicio titulo="Social Media" di="3"   refe={btnCard3} clic={e => cambiarInfo(e, 'socialMedia')}/>
      </div>
    </div>  
  )
};